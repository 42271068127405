import React, { useState, FC } from 'react'
import { SignInFieldsInterfaces } from './interfaces'
import { Input, Button, Checkbox } from 'antd'
import Spinner from '../../../core-ui/Spinner'
import { useNavigate } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

const SignInFields: FC<SignInFieldsInterfaces> = ({
  signInAction,
  signInStatus,
  setFormData,
  formData,
  signInData,
}) => {
  const navigate = useNavigate()
  const [buttonClick, setButtonClick] = useState(false)
  const [changeType, setChangeType] = useState(false)

  const handleChange = (event_: React.ChangeEvent<HTMLInputElement>, stateName: string) => {
    setButtonClick(false)
    const { value } = event_.target
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleClick = async () => {
    setButtonClick(true)

    if (formData.email !== '' && formData.password !== '') {
      signInAction({
        email: formData.email,
        password: formData.password,
      })
    }
  }
  const handleForgetClick = () => {
    navigate('/forgot-password')
  }

  return (
    <>
      <h1>Log in to your account</h1>
      <label>Email*</label>
      <Input onChange={(e) => handleChange(e, 'email')} placeholder='Enter your email' />
      {formData.email === '' && buttonClick ? (
        <p className='error-message '>*Please enter a valid email address</p>
      ) : (
        <p className='error-message message-disable'></p>
      )}
      <label>Password*</label>
      <Input
        onChange={(e) => handleChange(e, 'password')}
        placeholder='*********'
        type={changeType ? 'text' : 'password'}
        addonAfter={
          changeType ? (
            <EyeInvisibleOutlined onClick={() => setChangeType(false)} />
          ) : (
            <EyeOutlined onClick={() => setChangeType(true)} />
          )
        }
      />
      {formData.password === '' && buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid password</p>
      ) : (
        <p className='error-message message-disable'></p>
      )}
      <div className='check-box'>
        <div className='keep-box'>
          <Checkbox className='scales-checkbox' />
          <p>Keep me logged in</p>
        </div>
        <div onClick={handleForgetClick}>
          <p className='sign-in-text'>Forgot Password</p>
        </div>
      </div>
      {signInData && signInData.message && buttonClick && signInStatus !== 'pending' ? (
        <p className='error-message message-active'>{signInData.message}</p>
      ) : (
        <p className='error-message message-disable'></p>
      )}
      <Button onClick={handleClick}>
        {signInStatus === 'pending' ? <Spinner /> : 'Get started'}
      </Button>

      <p className='text-center availability-color'>Currently only available to US residents</p>
    </>
  )
}

export default SignInFields
